import React from 'react'
import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Link,
    Typography,
} from '@mui/material'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { format } from 'date-fns'

const isBrowser = typeof window !== 'undefined'

const ArticleCard = ({ article, blog }: any) => {
    const {
        site: {
            siteMetadata: { siteUrl },
        },
        defaultNoimage,
    } = useSiteMetadata()

    let description: string = ''
    if (isBrowser) {
        const div = document.createElement('div')
        div.innerHTML = (article.description ?? article.content).replace(/<img.*?>/g, '')
        description = div.innerText ?? ''
    }

    const articleGatsbyImage = getImage(article.localImage)

    return (
        <Card>
            <CardHeader
                avatar={
                    <Link href={blog.blog_url} underline="none">
                        {blog.image ? (
                            <Avatar
                                variant="square"
                                sx={{ width: 96, height: 54, bgcolor: '#FFF' }}
                            >
                                <GatsbyImage alt={blog.blog_id} image={getImage(blog.image)!} />
                            </Avatar>
                        ) : (
                            <Avatar variant="square" sx={{ width: 96, height: 54 }}>
                                {blog.blog_id.substring(0, 1).toUpperCase()}
                            </Avatar>
                        )}
                    </Link>
                }
                title={
                    <Link href={`/blog/${blog.blog_id}`} color="text.primary" underline="none">
                        {blog.site}
                    </Link>
                }
                subheader={
                    <div>
                        {format(new Date(article.created), 'yyyy年M月d日 H時')}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `
                                <a
                                    href="https://b.hatena.ne.jp/entry/${article.url
                                        .replace('http://', '')
                                        .replace('https://', 's/')}"
                                    class="hatena-bookmark-button"
                                    data-hatena-bookmark-layout="basic-label-counter"
                                    data-hatena-bookmark-lang="ja"
                                    title="このエントリーをはてなブックマークに追加"
                                >
                                    <img
                                        src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                                        alt="このエントリーをはてなブックマークに追加"
                                        width="20"
                                        height="20"
                                        style="border: none;"
                                    />
                                </a>
                                `,
                            }}
                        />
                    </div>
                }
                sx={{ p: 1 }}
            />
            <CardActionArea
                href={article.url}
                sx={[
                    {
                        '&:hover, &:hover *': { opacity: 1 },
                    },
                ]}
            >
                {articleGatsbyImage ? (
                    <CardMedia>
                        <GatsbyImage alt={article.title} image={articleGatsbyImage} />
                    </CardMedia>
                ) : (
                    <>
                        {article?.localImage?.publicURL ? (
                            <CardMedia
                                component="img"
                                loading="lazy"
                                src={article.localImage.publicURL}
                                alt={blog.blog_id}
                                sx={{
                                    aspectRatio: '1.77', // 16:9
                                }}
                            />
                        ) : (
                            <CardMedia>
                                <GatsbyImage
                                    alt={article.title}
                                    image={getImage(defaultNoimage)!}
                                />
                            </CardMedia>
                        )}
                    </>
                )}
                <CardContent sx={{ minHeight: 280 }}>
                    <Typography gutterBottom variant="h3">
                        {article.title}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant="body2"
                        color="text.secondary"
                        sx={{ minHeight: 12 }}
                    >
                        {!!article.categories.length &&
                            article.categories.map((item: any) => `[${item}] `)}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 6,
                        }}
                    >
                        {description.substring(0, 200)}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default ArticleCard
